.embla {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 200px;
}

.embla__viewport {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.embla__container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
}

.embla__slide {
  position: relative;
  flex: 0 0 25%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;
}

.embla__slide__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-container {
  text-align: center;
  margin: 2rem 0;
  background-color: whitesmoke;
}

.carousel-container h2 {
  font-family: 'Rajdhani', sans-serif;
  font-size: 2rem;
  margin-bottom: 1rem;
}
