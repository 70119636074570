.container {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  max-width: 1200px;
  margin: 2rem auto;
  color: white;
  width: 85%;
}

.message-container {
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.form-container {
  padding: 2rem;
  border-radius: 8px;
  position: relative;
}

.heading {
  font-family: 'Rajdhani', sans-serif;
  color: #77c4d6;
  font-size: 3rem;
}

.subheading {
  font-family: 'Montserrat', sans-serif;
  margin-top: 0.5rem;
}

.contact-details a {
  color: #77c4d6;
  text-decoration: none;
}

address {
  font-style: normal;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group.half-width {
  display: inline-block;
  width: 48%;
}

.form-group.half-width:first-child {
  margin-right: 4%;
}

.form-group label {
  display: block;
  margin-bottom: 1rem;
  font-family: 'Montserrat', sans-serif;
}

.form-group .asterisk {
  color: red;
  margin-left: 0.25rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 1rem;
  border-radius: 32px;
  border: 1px solid #ccc;
  font-family: 'Montserrat', sans-serif;
}

.form-group textarea {
  resize: none;
  height: 150px;
}

.consent-group {
  display: flex;
  align-items: center;
}

.consent-group input {
  width: auto;
  margin-right: 0.5rem;
}

button[type='submit'] {
  background-color: #77c4d6;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s;
}

button[type='submit']:hover {
  background-color: #5aa9c4;
}

button[type='submit'].disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.success-message {
  color: #4caf50;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1rem;
}

.error-message {
  color: #f44336;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
  }

  .form-group.half-width {
    width: 100%;
    margin-right: 0;
  }
}
