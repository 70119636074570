.privacy-policy-container {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  color: #333;
}

.privacy-policy-container h1,
.privacy-policy-container h2 {
  color: #77c4d6;
}

.privacy-policy-container p,
.privacy-policy-container ul {
  font-family: 'Montserrat', sans-serif;
  color: white;
}

.privacy-policy-container ul {
  list-style-type: disc;
  padding-left: 20px;
}
