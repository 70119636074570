body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #28282b;
}

.home {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-size: cover;
}

.content {
  flex: 1;
  margin-right: 1rem;
}

.home h1 {
  font-family: 'Rajdhani', sans-serif;
  color: white;
}

.image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem 0;
}

.card {
  color: var(--clr-neutral-100);
  background-color: var(--clr-neutral-900);
  padding: 1rem 0;
  max-width: 250px;
  border-radius: 0.5rem;
  overflow: hidden;
  transition: transform 500ms ease;
}

.card-image {
  width: 100%;
  height: auto;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card:hover,
.card:focus-within {
  transform: scale(1.05);
}

.card-content {
  --padding: 1.5rem;
  padding: var(--padding);
  background: linear-gradient(hsl(0 0% 0% / 0), hsl(20 0% 0% / 0.3) 20%, hsl(0 0% 0% / 1));
}

.card-title {
  position: relative;
  width: max-content;
}

.card:hover .card-title::after,
.card:focus-within .card-title::after {
  transform: scaleX(1);
}

.card-title::after {
  content: '';
  position: absolute;
  height: 4px;
  width: calc(100% + var(--padding));
  left: calc(var(--padding) * -1);
  bottom: -2px;
  background: var(--clr-accent-400);
  transform-origin: left;
  transition: transform 500ms ease;
}

@media (hover) {
  .card-content {
    transform: translateY(65%);
    transition: transform 500ms ease;
  }

  .card-content > *:not(.card-title) {
    opacity: 0;
    transition: opacity 500ms linear;
  }

  .card:hover .card-content > *:not(.card-title),
  .card:focus-within .card-content > *:not(.card-title) {
    opacity: 1;
    transition-delay: 700ms;
  }

  .card:hover .card-content,
  .card:focus-within .card-content {
    transform: translateY(0);
    transition-delay: 500ms;
  }

  .card:focus-within .card-content {
    transition-duration: 0ms;
  }

  .card-title::after {
    transform: scaleX(0);
  }
}

.button {
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: var(--clr-neutral-900);
  background-color: var(--clr-accent-400);
  padding: 0.5em 1.25em;
  border-radius: 0.25rem;
}

.button:hover,
.button:focus {
  background-color: var(--clr-neutral-100);
}

.card-body {
  color: rgba(255, 255, 255, 0.85);
}

@media (max-width: 1366px) {
  .card {
    max-width: 250px;
  }
}

@media (max-width: 768px) {
  .cards-container {
    flex-direction: column;
    align-items: center;
  }
  .card {
    max-width: 90%;
    margin: 1rem 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
    transition-delay: 0ms !important;
  }
}

.get-in-touch-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #77c4d6;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-align: center;
}

.get-in-touch-button:hover {
  background-color: #5aa9c4;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
