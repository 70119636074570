/* OurServices.css */

.services-container {
  padding: 2rem;
  background-color: #fff;
  color: #333;
}

.services-container h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.services-list {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.service-category {
  flex: 1 1 22%; /* Adjust the basis to fit four items in a row */
  min-width: 200px;
  padding: 1rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #f9f9f9;
}

.service-category h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.service-category ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.service-category li {
  margin-bottom: 0.5rem;
}

@media (max-width: 768px) {
  .services-list {
    flex-direction: column;
    align-items: center;
  }

  .service-category {
    width: 100%;
    max-width: 90%;
    margin-bottom: 1rem;
  }
}
