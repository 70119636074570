/* @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&family=Montserrat:wght@400&display=swap');

.footer {
  text-align: center;
  padding: 1rem;
  background-color: #2c3e50;
  color: #fff;
  position: relative;
}

.footer a {
  color: #fff;
}

.social-media-logo {
  width: 60px;
  height: auto;
  margin-top: 10px;
  border: 4px solid #fff;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  right: 20px;
  bottom: 10px;
} */

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&family=Montserrat:wght@400&display=swap');

.footer {
  text-align: center;
  padding: 1rem;
  background-color: #2c3e50;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.footer-left {
  text-align: center;
  width: 50%;
}

.footer-right {
  text-align: center;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer a {
  color: #fff;
  margin: 0 10px;
}

.social-media-logo {
  width: 50px; /* Increase size by 50% */
  height: auto;
  margin-top: 10px;
  border: 4px solid #fff;
  background-color: #fff;
  border-radius: 20%;
  padding: 5px;
}
