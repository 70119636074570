p {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  margin-bottom: 1rem;
}

a {
  color: #265d73;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

button {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  background-color: #265d73;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #5aa9c4;
}

input,
textarea,
select {
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 4px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

/* Header styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem 2rem;
  background-color: #2c3e50;
  z-index: 1000;
  box-sizing: border-box;
}

.logo-container {
  display: flex;
  justify-content: center;
  flex: 1;
}

.logo {
  height: 50px;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  position: relative;
}

.nav-links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: nowrap;
}

.nav-links li {
  margin: 0 0.5rem;
}

.nav-links li a {
  color: white;
  text-decoration: none;
  font-family: 'Rajdhani', sans-serif;
  font-weight: bold; /* Apply the "Call to action" style from brand guidelines */
  font-size: 1rem; /* Adjust font size as needed */
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    background-color: #2c3e50;
    flex-direction: column;
    align-items: flex-end;
    z-index: 999;
    width: 200px;
    padding: 0.5rem 0;
  }

  .nav-links li {
    width: 100%;
  }

  .nav-links li a {
    display: block;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .nav.open .nav-links {
    display: flex;
  }

  .menu-toggle {
    display: block;
  }
}

@media (max-width: 480px) {
  .header {
    padding: 0.5rem;
  }

  .logo-container {
    flex: 1;
  }

  .nav-links {
    justify-content: flex-end;
    flex: 2;
  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .nav-links li a {
    font-size: 0.9rem;
  }
}
