/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@700&family=Montserrat:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #000000;
  color: #333;
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rajdhani', sans-serif;
  margin: 0;
  color: #77c4d6;
}

p {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  margin-bottom: 1rem;
}

.main-content {
  padding-top: 60px;
}

header {
  height: 60px;
}

label {
  font-family: 'Montserrat', sans-serif;
  line-height: 1.6;
  margin-bottom: 1rem;
  color: #3c96ab;
}
