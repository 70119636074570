.services {
  padding: 2rem;
  background-color: #fff;
}

.services h1 {
  font-family: 'Rajdhani', sans-serif;
  color: #265d73;
}

.services p {
  font-family: 'Montserrat', sans-serif;
  color: #77c4d6;
}
